import React from 'react'
import PropTypes from 'prop-types'
import { normalizeString, capitalize } from '../utils/strings'
import Rating from './rating'
import { Link } from 'gatsby'

const SchoolTeaser = ({ school }) => (
  <Link
    to={`/colegio/${normalizeString(school.name, '-')}`}
    className='school-item-content'
  >
    <div className='school-item-info'>
      <p className='school-funding'>
        <span className={`funding-type ${normalizeString(school.fundingType)}`}>
          {school.fundingType.toLowerCase()}
        </span>
      </p>
      <h2>{capitalize(school.name)}</h2>
      <Rating value={Number(school.avgApprovedReviews)} />
      <div className='rating-count'>
        {school.countApprovedReviews}{' '}
        {school.countApprovedReviews === 1 ? 'opinión' : 'opiniones'}
      </div>
    </div>
  </Link>
)

SchoolTeaser.propTypes = {
  school: PropTypes.object.isRequired,
}

export default SchoolTeaser
