import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Filters from '../components/filters'
import BackgroundImage from 'gatsby-background-image'
import SchoolTeaser from '../components/schoolTeaser'
import { slugToFilters } from '../utils/filters'
import { window, document } from 'browser-monads'

const SiteIndex = ({ data }) => {
  const [filters, setFilters] = useState({})

  useEffect(() => {
    const pathName = document.location.pathname
    if (pathName !== '/') {
      const filtersFromSlug = slugToFilters(pathName)
      if (filtersFromSlug !== null) {
        setFilters(filtersFromSlug)
      }
    }
  }, [document.location.pathname])

  const updateUrl = newPath => {
    window.history.pushState(
      {},
      'Filtro',
      `${document.location.origin}${newPath}`
    )
  }

  const schools = data.allAirtable.edges
    .map(item => item.node.data)
    .filter(school => school.name)

  const filteredSchools = schools.filter(school => {
    if (Object.keys(filters).length === 0) {
      // if there are no filters, show this school
      return true
    }

    return Object.keys(filters).every(filterType => {
      if (filters[filterType]?.size === 0) {
        // if there are no values for this filter, keep looking for other conditions
        return true
      }

      // if the school doesn't have a value for this property, don't show this school
      if (!school[filterType]) {
        return false
      }
      // show the school if some of the filter values are present in the school's prop
      return Array.from(filters[filterType]).some(filterValue => {
        return school[filterType].indexOf(filterValue) !== -1
      })
    })
  })

  return (
    <Layout filters={filters} isFront schools={schools} filterSchoolsCount={filteredSchools.length}>
      <Filters
        filters={filters}
        setFilters={setFilters}
        updateUrl={updateUrl}
      />
      <ul className='school-grid'>
        {filteredSchools.map(school => (
          <li className='school-item' key={school.name}>
            {school.teaserImage?.localFiles?.[0]?.childImageSharp?.fluid ? (
              <BackgroundImage
                fluid={
                  school.teaserImage?.localFiles?.[0].childImageSharp?.fluid
                }
              >
                <SchoolTeaser school={school} />
              </BackgroundImage>
            ) : (
              <div style={{ backgroundColor: '#8a8a8a', height: '100%' }}>
                <SchoolTeaser school={school} />
              </div>
            )}
          </li>
        ))}
      </ul>
    </Layout>
  )
}

SiteIndex.propTypes = {
  data: PropTypes.shape({
    allAirtable: PropTypes.object,
  }),
}

export default SiteIndex

export const pageQuery = graphql`
  query {
    allAirtable(
      filter: { table: { eq: "zaragoza_schools" } }
      sort: { fields: data___countApprovedReviews, order: DESC }
    ) {
      edges {
        node {
          data {
            name
            avgApprovedReviews
            fundingType
            educationFilter
            countApprovedReviews
            teaserImage {
              localFiles {
                absolutePath
                childImageSharp {
                  fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
