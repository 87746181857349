import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import {
  filterDisplayName,
  initializePossibleFilterValues,
  filtersToSlug,
} from '../utils/filters'

const Filters = ({ setFilters, filters, updateUrl }) => {
  const data = useStaticQuery(graphql`
    query FilterQuery {
      allAirtable(
        filter: { table: { eq: "zaragoza_schools_filters" } }
        sort: { fields: data___order, order: ASC }
      ) {
        group(field: data___filterType) {
          fieldValue
          edges {
            node {
              data {
                name
                order
              }
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    const cleanData = {}
    data.allAirtable.group.forEach(({ fieldValue, edges }) => {
      cleanData[fieldValue] = edges.map(edge => edge.node.data)
    })
    initializePossibleFilterValues(cleanData)
  }, [data])

  const availableFilters = {}
  data.allAirtable.group.forEach(group => {
    const values = group.edges.map(item => item.node.data.name)
    availableFilters[group.fieldValue] = values
  })

  return (
    <div className='school-filters'>
      {Object.keys(availableFilters).map(filterType => (
        <div className='filter' key={filterType}>
          <h3>{filterDisplayName(filterType)}</h3>
          <ul>
            {availableFilters[filterType].map(value => (
              <li key={value}>
                <button
                  onClick={() => {
                    const nextFilterTypeValue = filters[filterType]
                      ? new Set(filters[filterType])
                      : new Set()

                    if (!nextFilterTypeValue.delete(value)) {
                      nextFilterTypeValue.add(value)
                    }

                    const nextFilters = {
                      ...filters,
                      [filterType]: nextFilterTypeValue,
                    }
                    if (nextFilters[filterType].size === 0) {
                      delete nextFilters[filterType]
                    }
                    setFilters(nextFilters)

                    const sluggifiedFilters = filtersToSlug(nextFilters)
                    updateUrl(sluggifiedFilters)
                  }}
                  className={filters[filterType]?.has(value) ? 'selected' : ''}
                >
                  {value}
                </button>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  )
}

Filters.propTypes = {
  setFilters: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  updateUrl: PropTypes.func.isRequired,
}

export default Filters
